import { BlockType, IncutType, ListType } from 'pn-backend';
import React, { FC, useState } from 'react';

import { Chevron } from '~assets';
import { Grid, LI, OL, Text, UL } from '~atoms';
import { Tooltips } from '~components';
import { BlockProps } from '~types';

const defaultProps = {
  $margin: 'auto',
  $marginTop: '40px',
  $maxWidth: '750px',
  $width: '100%',
};

type ListProps = {
  items: string[];
  listType: ListType;
};

export const List: FC<ListProps> = ({ items, listType }) => {
  return (
    <Grid $marginTop="20px" $sm={{ $marginTop: '15px' }}>
      {listType === ListType.Dotted && (
        <UL>
          {items.map((listElemHtml, i) => (
            <LI key={i} $sm={{ $padding: '0 0 0 5px' }}>
              <Text
                $weight={400}
                $size={20}
                $lineHeight={30}
                $color="black"
                $textWithLink
                $sm={{ $lineHeight: 27, $size: 18 }}
                dangerouslySetInnerHTML={{ __html: listElemHtml }}
              />
            </LI>
          ))}
        </UL>
      )}

      {listType === ListType.Numbered && (
        <OL>
          {items.map((listElemHtml, i) => (
            <LI key={i} $sm={{ $padding: '0 0 0 5px' }}>
              <Text
                $weight={400}
                $size={20}
                $lineHeight={30}
                $color="black"
                $textWithLink
                $sm={{ $lineHeight: 27, $size: 18 }}
                dangerouslySetInnerHTML={{ __html: listElemHtml }}
              />
            </LI>
          ))}
        </OL>
      )}
    </Grid>
  );
};

export const IncutBlock: FC<BlockProps<BlockType.Incut>> = ({
  compiled: { caption, html, items, listType, type },
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClick = () => setIsOpen(!isOpen);

  const isHasText = !!html;

  const isListNotEmpty = items?.length > 0 && items[0].length > 0;

  switch (type) {
    case IncutType.Expander:
      return (
        <Grid
          {...defaultProps}
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{ $marginTop: '30px' }}
        >
          <Grid
            $border="1px solid var(--color-black)"
            $transition="all 0.5s ease-in-out"
            $overflow="hidden"
            $position="relative"
          >
            <Grid $position="relative" $padding="30px 30px 0" $sm={{ $padding: '15px 20px 0' }}>
              <Text
                $size={20}
                $lineHeight={26}
                $weight={600}
                $textTransform="uppercase"
                $marginBottom="26px"
                $padding="0 32px 0 0"
                $cursor="pointer"
                onClick={onClick}
                $sm={{ $lineHeight: 27, $marginBottom: '15px', $padding: '0 22px 0 0', $size: 18 }}
              >
                {caption}
              </Text>
              <Grid
                $position="absolute"
                $top="0"
                $right="25px"
                $height="100%"
                $width="32px"
                $transition="all 0.3s ease-in-out"
                $transform={isOpen ? 'rotate(180deg)' : 'rotate(360deg)'}
                onClick={onClick}
                $cursor="pointer"
                $alignItems="center"
                $sm={{ $right: '10px' }}
              >
                <Chevron />
              </Grid>
            </Grid>

            <Grid $padding="0 30px" $sm={{ $padding: '0 20px' }}>
              <Grid
                $maxHeight={isOpen ? '1500px' : '0'}
                $padding={isOpen ? '0 0 30px' : '0'}
                $transition=" all 0.2s ease-in-out"
              >
                {isHasText && (
                  <>
                    <Tooltips html={html} />
                    <Text
                      $size={20}
                      $lineHeight={30}
                      $weight={400}
                      $textWithLink
                      $display="grid"
                      $gap="30px"
                      $sm={{ $lineHeight: 27, $size: 18 }}
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                  </>
                )}
                <div>{isListNotEmpty && <List items={items} listType={listType} />}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );

    case IncutType.Text:
      return (
        <Grid
          {...defaultProps}
          $md={{ $maxWidth: 'unset', $padding: '0 15px' }}
          $sm={{ $marginTop: '30px' }}
        >
          <Grid $padding="30px" $border="1px solid var(--color-black)" $sm={{ $padding: '20px' }}>
            {caption && (
              <Text
                $size={20}
                $lineHeight={26}
                $weight={600}
                $textTransform="uppercase"
                $marginBottom="20px"
                $sm={{ $lineHeight: 27, $marginBottom: '15px', $size: 18 }}
              >
                {caption}
              </Text>
            )}
            {isHasText && (
              <>
                <Tooltips html={html} />
                <Text
                  $size={20}
                  $lineHeight={30}
                  $weight={400}
                  $textWithLink
                  $display="grid"
                  $gap="30px"
                  $sm={{ $lineHeight: 27, $size: 18 }}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </>
            )}
            {isListNotEmpty && <List items={items} listType={listType} />}
          </Grid>
        </Grid>
      );

    default:
      return null;
  }
};
