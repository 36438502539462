// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import classNames from 'classnames';
import parse from 'html-react-parser';
import loadScript from 'load-script';
import { BlockType } from 'pn-backend';
import { identity } from 'ramda';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { Grid } from '~atoms';
import { useLayout } from '~hooks';
import { BlockProps, WidgetType } from '~types';
import { detectSafari, vkPlaylistWidget, vkWidget } from '~utils';

import styles from './styles.module.scss';
import { getAspectRatio, getType } from './utils';

const defaultProps = {
  $margin: '40px auto 0',
  $maxWidth: '750px',
};

const scripts = {
  [WidgetType.facebook]: {
    crossorigin: 'anonymous',
    id: 'facebook-wjs',
    nonce: 'SRzOQrHN',
    src: 'https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v15.0',
  },
  [WidgetType.inst]: {
    id: 'inst-wjs',
    src: 'https://platform.instagram.com/en_US/embeds.js',
  },
  [WidgetType.twitter]: {
    id: 'twitter-wjs',
    src: 'https://platform.twitter.com/widgets.js',
  },
  [WidgetType.sporcle]: {
    id: 'sporcle-wjs',
    src: 'https://www.sporcle.com/embed/embed.js',
  },
};

export const WidgetBlock: FC<BlockProps<BlockType.Widget>> = ({ compiled: { format, html } }) => {
  const type = getType(html);
  const inputEl = useRef(null);
  const { isCard } = useLayout();
  const [isLoaded, setIsLoaded] = useState(false);

  const [isSafari, setIsSafari] = useState();

  useEffect(() => {
    setIsSafari(detectSafari());
  }, []);

  useEffect(() => {
    if (!type || !format || !html) return;

    if (type === WidgetType.sporcle) {
      const script = scripts[type];
      loadScript(script.src, { attrs: { id: script.id } }, identity);
      inputEl.current.innerHTML = html;
      return;
    }

    if (type === WidgetType.VK_PLAYLIST) {
      vkPlaylistWidget(html, inputEl);
    }

    if (type === WidgetType.infogram || type === WidgetType.telegram) {
      const parsedHtml = parse(html) as { props: { id: string; src: string } };
      const script = document.createElement('script');

      if (parsedHtml?.props) {
        Object.keys(parsedHtml.props).map((key) => {
          if (key === 'data-telegram-post') return null;

          return script.setAttribute(key, parsedHtml.props[key]);
        });

        inputEl.current.appendChild(script);
      }
    }

    if (type === WidgetType.telegram) {
      const script = scripts[type];
      if (script) {
        loadScript(script.src, { attrs: { id: script.id } }, identity);
      } else if (type === WidgetType.VK_POST) {
        vkWidget(html, inputEl);
      } else if (typeof inputEl?.current?.innerHTML !== 'undefined') {
        inputEl.current.innerHTML = html;
      }
    }
  }, [format, html, type]);

  if (!type || !format || !html) return null;

  const aspectRatio = getAspectRatio(type, format, html);

  const onEnter = () => {
    if (isLoaded || type === WidgetType.telegram) return;
    setIsLoaded(true);

    const script = scripts[type];
    if (script) {
      loadScript(script.src, { attrs: { id: script.id } }, identity);
    } else if (type === WidgetType.VK_POST) {
      vkWidget(html, inputEl);
    } else if (typeof inputEl?.current?.innerHTML !== 'undefined') {
      inputEl.current.innerHTML = html;
    }
  };

  return (
    <Grid className={styles.widget}>
      <Waypoint onEnter={onEnter} topOffset="200px" />
      <Grid
        {...defaultProps}
        ref={inputEl}
        className={classNames(
          styles.wrapper,
          styles[type],
          styles[format],
          isCard && styles.cardStyles,
          isSafari && styles.isSafari,
        )}
        $width={aspectRatio < 1 && type === 'VK' ? 'unset' : '100%'}
        $maxWidth={format === 'fullscreen' ? 'unset' : '750px'}
        $aspectRatio={format === 'fullscreen' ? aspectRatio : 'unset'}
        $marginTop={isCard ? '0' : '40px'}
        $lg={{ $margin: '40px 15px 0', $maxWidth: 'unset', $width: 'unset' }}
        $sm={{
          $aspectRatio: getAspectRatio(type, format, html),
          $margin: isCard ? '0' : '30px 15px 0',
        }}
      />
    </Grid>
  );
};
