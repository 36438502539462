/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import { orderBy } from 'lodash';

import {
  Block,
  BlockChildMap,
  BlockCompiled,
  BlockContentCardCompiled,
  BlockContentColumnsCompiled,
  BlockContentCoverCompiled,
  BlockContentDescriptionCompiled,
  BlockContentGalleryCompiled,
  BlockContentGIFCompiled,
  BlockContentH1Compiled,
  BlockContentH2Compiled,
  BlockContentH3Compiled,
  BlockContentImageCompiled,
  BlockContentIncutCompiled,
  BlockContentIntroCompiled,
  BlockContentLineCompiled,
  BlockContentListCompiled,
  BlockContentMetaCompiled,
  BlockContentMoreOnTopicCompiled,
  BlockContentNewsSourceCompiled,
  BlockContentPreviewCompiled,
  BlockContentQuizCompiled,
  BlockContentQuoteCompiled,
  BlockContentSEOTitleCompiled,
  BlockContentSpecLinkCompiled,
  BlockContentSubtitleCompiled,
  BlockContentTestQuestionCompiled,
  BlockContentTestResultsCompiled,
  BlockContentTextCompiled,
  BlockContentVideoCompiled,
  BlockContentWidgetCompiled,
  BlockType,
  BlockTypeContentCompiledMap,
  File,
  FileType,
  PreviewImageFormat,
} from '../types';
import { CompileOptions } from '../types/common/CompileOptions';
import { BlockContentTestCompiled } from '../types/db/block/compiled/BlockContentTestCompiled';
import { draftToHTML } from '../util';
import { EMPTY_RAW_DRAFT_CONTENT_STATE } from '../util/draft/consts';
// eslint-disable-next-line import/no-cycle
import { compileBlock } from './compileBlock';
import { getBlockFile } from './getBlockFile';
import { getFileCompiledURL } from './getFileCompiledURL';
import { getFileURL } from './getFileURL';

type BlockTypeContentCompileMap<T extends BlockType = BlockType> = {
  [K in T]: (
    input: Block<K>,
    options: CompileOptions,
  ) => BlockTypeContentCompiledMap[K];
};

const ENABLE_CARD_BLOCK_IDS = false;

export const blockTypeContentCompileMap: BlockTypeContentCompileMap<BlockType> =
  {
    card(
      input: Block<BlockType.Card>,
      options: CompileOptions,
    ): BlockContentCardCompiled {
      let blocks: BlockCompiled<BlockChildMap<BlockType.Card>>[];

      if (ENABLE_CARD_BLOCK_IDS && input.content?.blockIds && input.blocks) {
        blocks = input.content.blockIds
          .map((blockId) => input.blocks.find((block) => block.id === blockId))
          .filter(Boolean)
          .map((block) =>
            compileBlock(
              block as unknown as Block<BlockChildMap<BlockType.Card>>,
              options,
            ),
          );
      } else {
        blocks =
          orderBy(input.blocks || [], 'sortOrder').map((block) =>
            compileBlock(block, options),
          ) || [];
      }

      return { blocks };
    },
    columns(
      input: Block<BlockType.Columns>,
      options: CompileOptions,
    ): BlockContentColumnsCompiled {
      return {
        html: draftToHTML(input.content.content),
        images: (input.content.images || []).map((item) => ({
          caption: item.caption,
          imageURL: getFileCompiledURL(
            input,
            item.fileId,
            options.imageProxy[BlockType.Columns],
          ),
        })),
        textPosition: input.content.textPosition,
        title: input.content.title,
      };
    },
    cover(
      input: Block<BlockType.Cover>,
      options: CompileOptions,
    ): BlockContentCoverCompiled {
      const file = getBlockFile(input, input.content.fileId) as File<
        Date,
        FileType.Image
      >;

      return {
        caption: input.content.caption,
        format: input.content.format,
        height: file?.meta?.height || 0,
        hideInPost: input.content.hideInPost,
        imageURL: file
          ? getFileURL(
              file,
              options.imageProxy[BlockType.Cover][input.content.format] ||
                options.imageProxy.default,
            )
          : '',
        width: file?.meta?.width || 0,
      };
    },
    description(
      input: Block<BlockType.Description>,
      options: CompileOptions,
    ): BlockContentDescriptionCompiled {
      return {
        text: input.content.text,
      };
    },
    gallery(
      input: Block<BlockType.Gallery>,
      options: CompileOptions,
    ): BlockContentGalleryCompiled {
      return {
        images: (input.content.images || [])
          .map((image) => ({
            file: getBlockFile(input, image.fileId) as File<
              Date,
              FileType.Image
            >,
            height: image.height,
            image,
            width: image.width,
          }))
          .map(({ file, height, image, width }) => ({
            caption: image.caption,
            height: height || file?.meta?.height || 0,
            imageURL: file
              ? getFileURL(
                  file,
                  options.imageProxy[BlockType.Gallery][input.content.type] ||
                    options.imageProxy.default,
                )
              : '',
            width: width || file?.meta?.width || 0,
          })),
        type: input.content.type,
      };
    },
    gif(
      input: Block<BlockType.Gif>,
      options: CompileOptions,
    ): BlockContentGIFCompiled {
      const file = getBlockFile(input, input.content.fileId) as File<
        Date,
        FileType.Image
      >;

      return {
        caption: input.content.caption,
        height: file?.meta?.height || 0,
        imageURL: file
          ? getFileURL(file, options.imageProxy[BlockType.Gif])
          : '',
        width: file?.meta?.width || 0,
      };
    },
    h1(
      input: Block<BlockType.H1>,
      options: CompileOptions,
    ): BlockContentH1Compiled {
      return {
        text: input.content.text,
      };
    },
    h2(
      input: Block<BlockType.H2>,
      options: CompileOptions,
    ): BlockContentH2Compiled {
      return {
        html: draftToHTML(
          input.content.content || EMPTY_RAW_DRAFT_CONTENT_STATE,
        ),
      };
    },
    h3(
      input: Block<BlockType.H3>,
      options: CompileOptions,
    ): BlockContentH3Compiled {
      return {
        html: draftToHTML(
          input.content.content || EMPTY_RAW_DRAFT_CONTENT_STATE,
        ),
      };
    },
    image(
      input: Block<BlockType.Image>,
      options: CompileOptions,
    ): BlockContentImageCompiled {
      const file = getBlockFile(input, input.content.fileId) as File<
        Date,
        FileType.Image
      >;

      return {
        caption: input.content.caption,
        format: input.content.format,
        height: file?.meta?.height || 0,
        imageURL: file
          ? getFileURL(
              file,
              options.imageProxy[BlockType.Image][input.content.format] ||
                options.imageProxy.default,
            )
          : '',
        width: file?.meta?.width || 0,
      };
    },
    incut(
      input: Block<BlockType.Incut>,
      options: CompileOptions,
    ): BlockContentIncutCompiled {
      return {
        caption: input.content.caption,
        html: draftToHTML(
          input.content.content || EMPTY_RAW_DRAFT_CONTENT_STATE,
        ),
        items: input.content.items,
        listType: input.content.listType,
        type: input.content.type,
      };
    },
    intro(
      input: Block<BlockType.Intro>,
      options: CompileOptions,
    ): BlockContentIntroCompiled {
      return {
        html: draftToHTML(
          input.content.content || EMPTY_RAW_DRAFT_CONTENT_STATE,
        ),
      };
    },
    line(
      input: Block<BlockType.Line>,
      options: CompileOptions,
    ): BlockContentLineCompiled {
      return {
        type: input.content.type,
      };
    },
    list(
      input: Block<BlockType.List>,
      options: CompileOptions,
    ): BlockContentListCompiled {
      return {
        options:
          input.content.options?.map((item) => ({
            html: draftToHTML(item.content || EMPTY_RAW_DRAFT_CONTENT_STATE),
          })) ?? [],
        type: input.content.type,
      };
    },
    meta(
      input: Block<BlockType.Meta>,
      options: CompileOptions,
    ): BlockContentMetaCompiled {
      return {
        canonical: input.content.canonical,
      };
    },
    moreOnTopic(
      input: Block<BlockType.MoreOnTopic>,
      options: CompileOptions,
    ): BlockContentMoreOnTopicCompiled {
      return {
        imageURL: getFileCompiledURL(
          input,
          input.content.fileId ?? null,
          options.imageProxy[BlockType.MoreOnTopic],
        ),
        items: input.content.items,
        type: input.content.type,
      };
    },
    newsSource(
      input: Block<BlockType.NewsSource>,
      options: CompileOptions,
    ): BlockContentNewsSourceCompiled {
      return {
        name: input.content.name,
        url: input.content?.url,
      };
    },
    preview(
      input: Block<BlockType.Preview>,
      options: CompileOptions,
    ): BlockContentPreviewCompiled {
      return {
        imageURLs: Object.values(PreviewImageFormat).reduce(
          (acc, format) => ({
            ...acc,
            [format]: getFileCompiledURL(input, input.content.fileId, {
              ...((input.content.images && input.content.images[format]) || {}),
              domain: options.imageProxy.default.domain,
            }),
          }),
          {},
        ) as any,
      };
    },
    quiz(
      input: Block<BlockType.Quiz>,
      options: CompileOptions,
    ): BlockContentQuizCompiled {
      return {
        answerOptions: input.content.answerOptions.map((title) => ({
          percent: 0,
          selected: false,
          title,
        })),
        caption: input.content.caption,
        imageURL: getFileCompiledURL(
          input,
          input.content.fileId,
          options.imageProxy[BlockType.Quiz],
        ),
        question: input.content.question,
        voted: false,
      };
    },
    quote(
      input: Block<BlockType.Quote>,
      options: CompileOptions,
    ): BlockContentQuoteCompiled {
      return {
        caption: input.content.caption,
        expertName: input.content.expertName,
        html: draftToHTML(
          input.content.content || EMPTY_RAW_DRAFT_CONTENT_STATE,
        ),
        imageURL: getFileCompiledURL(
          input,
          input.content.fileId,
          options.imageProxy[BlockType.Quote],
        ),
        type: input.content.type,
      };
    },
    seoTitle(
      input: Block<BlockType.SeoTitle>,
      options: CompileOptions,
    ): BlockContentSEOTitleCompiled {
      return {
        text: input.content.text,
      };
    },
    specLink(
      input: Block<BlockType.SpecLink>,
      options: CompileOptions,
    ): BlockContentSpecLinkCompiled {
      return {
        url: input.content.url,
      };
    },
    subtitle(
      input: Block<BlockType.Subtitle>,
      options: CompileOptions,
    ): BlockContentSubtitleCompiled {
      return {
        text: input.content.text,
      };
    },
    test(
      input: Block<BlockType.Test>,
      options: CompileOptions,
    ): BlockContentTestCompiled {
      let blocks: BlockCompiled<BlockChildMap<BlockType.Test>>[];

      if (ENABLE_CARD_BLOCK_IDS && input.content?.blockIds && input.blocks) {
        blocks = input.content.blockIds
          .map((blockId) => input.blocks.find((block) => block.id === blockId))
          .filter(Boolean)
          .map((block) =>
            compileBlock(
              block as unknown as Block<BlockChildMap<BlockType.Test>>,
              options,
            ),
          );
      } else {
        blocks =
          orderBy(input.blocks || [], 'sortOrder').map((block) =>
            compileBlock(block, options),
          ) || [];
      }

      return { blocks };
    },
    testQuestion(
      input: Block<BlockType.TestQuestion>,
      options: CompileOptions,
    ): BlockContentTestQuestionCompiled {
      return {
        answerOptions: (input.content.answerOptions || []).map((item) => ({
          imageURL: getFileCompiledURL(
            input,
            item.fileId,
            options.imageProxy[BlockType.TestQuestion].answers,
          ),
          isRightAnswer: item.isRightAnswer,
          text: item.text,
        })),
        answerType: input.content.answerType,
        coverCaption: input.content.coverCaption,
        coverURL: getFileCompiledURL(
          input,
          input.content.fileId,
          options.imageProxy[BlockType.TestQuestion].cover,
        ),
        hideRightAnswer: input.content.hideRightAnswer,
        question: input.content.question,
        rightAnswerCaption: input.content.rightAnswerCaption,
        wrongAnswerCaption: input.content.wrongAnswerCaption,
      };
    },
    testResults(
      input: Block<BlockType.TestResults>,
      options: CompileOptions,
    ): BlockContentTestResultsCompiled {
      return {
        average: {
          caption: input.content.average?.caption,
          imageURL: getFileCompiledURL(
            input,
            input.content.average?.fileId,
            options.imageProxy[BlockType.TestResults].average,
          ),
          text: input.content.average?.text,
          title: input.content.average?.title,
          valuesFrom: input.content.average?.valuesFrom,
          valuesTo: input.content.average?.valuesTo,
        },

        maximum: {
          caption: input.content.maximum?.caption,
          imageURL: getFileCompiledURL(
            input,
            input.content.maximum?.fileId,
            options.imageProxy[BlockType.TestResults].maximum,
          ),
          text: input.content.maximum?.text,
          title: input.content.maximum?.title,
          valuesFrom: input.content.maximum?.valuesFrom,
          valuesTo: input.content.maximum?.valuesTo,
        },

        minimum: {
          caption: input.content.minimum?.caption,
          imageURL: getFileCompiledURL(
            input,
            input.content.minimum?.fileId,
            options.imageProxy[BlockType.TestResults].minimum,
          ),
          text: input.content.minimum?.text,
          title: input.content.minimum?.title,
          valuesFrom: input.content.minimum?.valuesFrom,
          valuesTo: input.content.minimum?.valuesTo,
        },
      };
    },
    text(
      input: Block<BlockType.Text>,
      options: CompileOptions,
    ): BlockContentTextCompiled {
      return {
        html: draftToHTML(
          input.content.content || EMPTY_RAW_DRAFT_CONTENT_STATE,
        ),
      };
    },
    video(
      input: Block<BlockType.Video>,
      options: CompileOptions,
    ): BlockContentVideoCompiled {
      return {
        caption: input.content.caption,
        cycle: input.content.cycle,
        format: input.content.format,
        posterURL: getFileCompiledURL(
          input,
          input.content.posterId ?? null,
          options.imageProxy.default,
        ),
        type: input.content.type,
        videoURL: getFileCompiledURL(input, input.content.fileId, {
          ...options.imageProxy.default,
          autoplay: false,
          loop: input.content.cycle,
        }),
      };
    },
    widget(
      input: Block<BlockType.Widget>,
      options: CompileOptions,
    ): BlockContentWidgetCompiled {
      return {
        format: input.content.format,
        html: input.content.html,
      };
    },
  };
